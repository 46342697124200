@import "../../variables/t-base";

.nav-tabs {
  .nav-item {
    .nav-link {
      font-weight: bold;

      &.active {
        box-shadow: inset 0 -3px $t-Color__secondary;
      }
    }
  }
}
