$p-Members__list__element--user__width: 262px !default;
$p-Members__list__element--seniority: 101px !default;

$p-Members__list__element__fontWeight: $t-Typography__fontWeight--bold !default;

%p-Members__list__element {
  float: left;
  height: 37px;
  a {
    font-weight: $p-Members__list__element__fontWeight;
    display: inline-block;
  }
}

.p-Members__list__element--user { //.blueheaderbar .user
  @extend %p-Members__list__element;
  width: $p-Members__list__element--user__width;
}

.p-Members__list__element--points { //.blueheaderbar .points
  @extend %p-Members__list__element;
  width: 80px;
  padding-left: 11px;
}

.p-Members__list__element--activity { //.blueheaderbar .activity
  @extend %p-Members__list__element;
  width: 63px;
}

.p-Members__list__element--category { //.blueheaderbar .category
  @extend %p-Members__list__element;
  width: 73px;
}

.p-Members__list__element--seniority { //.blueheaderbar .seniority
  @extend %p-Members__list__element;
  width: $p-Members__list__element--seniority;
}
