$p-About__checkout__backgroundColor: $t-Color__primary__gray--light !default;
$p-About__contribute__background: url(/images/about/getinvolved-top_illustration.jpg) no-repeat !default;
$p-About__ge1__background: url(/images/about/getinvolved-row-01.png) no-repeat 42px 0 !default;
$p-About__ge2__background: url(/images/about/getinvolved-row-02.png) no-repeat 31px 20px !default;
$p-About__ge3__background: url(/images/about/getinvolved-row-03.png) no-repeat 10px 0 !default;
$p-About__ge4__background: url(/images/about/getinvolved-row-04.png) no-repeat 20px 15px !default;
$p-About__getinvolved__backgroundColor: $t-Color__tan !default;
$p-About__approachIllustration__p__fontWeight: $t-Typography__fontWeight !default;

/* ABOUT ------------------------------------------------------------------------------------- */


//Specific classes for about pages
.deciding {
  margin-top: 40px;
  margin-bottom: 20px
}

.breaking {
  background: url(/images/about/how-break-1_small.jpg) no-repeat;
  margin-top: 19px;
  width: 460px;
  padding: 0 0 0 260px;
  margin-bottom: 48px
}

.breaking2 {
  padding: 30px 0 0 12px;
}

.putting {
  background: url(/images/about/how-putting.jpg) no-repeat;
  margin: 35px 0 40px 0;
  width: 365px;
  height: 179px;
  padding: 40px 0 0 310px;
}

.putting img {
  float: left;
  margin-right: 40px;
}

//TODO COLAB-2677: remove once replaced in web contents
.continue {
  text-align: center;
}

.continue a {
  padding: 28px 24px 24px;
  display: inline-block;
  background: $t-Color__primary__gray--light;
  font-size: 18px;
  font-weight: $t-Typography__fontWeight--bold;
  text-align: center;
  vertical-align: middle;
  border-radius: 12px;
}

.contribute {
  min-height: 165px;
  background: $p-About__contribute__background;
  padding: 34px 0 0 320px;
  margin-top: 55px;
}

.getinvolved-img {
  height: 118px;
  margin-top: 56px;
  position: relative;
  z-index: 5;
}

.ge1, .ge2, .ge3, .ge4 {
  height: 118px;
  float: left;
}

.ge1 {
  background: $p-About__ge1__background;
  width: 182px;
}

.ge2 {
  background: $p-About__ge2__background;
  width: 180px;
}

.ge3 {
  background: $p-About__ge3__background;
  width: 182px;
}

.ge4 {
  background: $p-About__ge4__background;
  width: 148px;
}

.getinvolved {
  background: $p-About__getinvolved__backgroundColor;
  padding: 42px 22px 2px;
  margin-top: -40px;
}

.getinvolved:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

.getinvolved {
  display: inline-block;
}

* html .getinvolved {
  height: 1%;
}

.getinvolved {
  display: block;
}

.getinvolved h4 {
  font-size: 22px;
  color: black;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.ge1b, .ge2b, .ge3b, .ge4b {
  float: left;
  font-size: 14px;
  line-height: normal;
}

.ge1b {
  width: 162px;
  margin-right: 20px;
}

.ge2b {
  width: 142px;
  margin-right: 38px;
}

.ge3b {
  width: 164px;
  margin-right: 18px;
}

.ge4b {
  width: 148px;
}

.checkout-wrap {
  border: 1px dashed $t-Color__gray--dark-600;
  width: 553px;
  padding: 5px;
  margin: 52px auto 0;
}

.checkout {
  background: url(/images/pattern-6x6-white.png) $p-About__checkout__backgroundColor;
  padding: 24px 16px 26px 32px;
  font-size: 17px;
  line-height: normal;
}

.checkout p {
  padding-right: 55px;
  background: url(/images/about/continue-arrow.png) no-repeat right center;
  margin: 0;
}

.checkout a {
  font-weight: $t-Typography__fontWeight--bold;
}

.p-About__approachIllustration {
  margin-top: 30px;
  background: $t-Color__tan;
  border-top: 1px solid $t-Color__gray--dark-600;
  border-bottom: 1px solid $t-Color__gray--dark-600;

  h3 {
    text-align: center;
    text-transform: uppercase;
  }

  p {
    text-align: center;
    font-weight: $p-About__approachIllustration__p__fontWeight;
  }

  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .p-About__approachIllustration__col {
    display: inline-block;
    width: 230px;
    padding-bottom: 30px;

    &:first-of-type {
      margin-left: 15px;
    }
  }
}
