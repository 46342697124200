/** Contests list **/
.contestlist.table .column.contestName {
    width: 366px;
}

.contestlist.table .column.contestName .contestFlag {
    position: relative;
}

.contestlist.table .column.contestProposalsCount {
    width: 31px;
}

.contestlist.table .column.contestCommentsCount {
    width: 35px;
}

.contestlist.table .column.contestWhat {
    width: 118px;
}

.contestlist.table .column.contestWhere {
    width: 105px;
}

.contestlist.table .column.contestWho {
    width: 105px;
}

.contestlist.table .column.contestHow {
    width: 105px;
}

/** PROPOSAL DETAILS **/

.contributors td, .judgingForm td {
    width: 525px;
}

/** edit proposal sections **/
#proposalPitchInput {
    height: 100px;
}

.judgingForm tr.odd td {
    background: #f2f1e9;
}

.judgingForm td {
    text-align: center;
    vertical-align: middle;
}

#copyProposalPopupContent tr:nth-child(even) {
    background-color: #f3f8fa;
}

#copyProposalPopupContent td {
    padding: 5px;

}

#copyProposalPopupContent td .sectionContent {
    display: none;
}

#copyProposalPopupContent td .hideContent {
    display: none;
}

#copyProposalPopupContent .sectionPresent {
    font-size: 0.8em;
    color: #FF4444;
}

.c-Box.empty .copyFromBaseProposalTriggerContainer {
    display: block;
}

.c-Box.notempty .copyFromBaseProposalTriggerContainer {
    display: none;
}

div.review-status-container {
    position: relative;
    display: inline-block;
    margin-top: 10px;
}
div.judge-review-status {
    float: left;
    display: inline;
    margin: 8px;
    text-align: center;
    width: 126px;
}

img.review-status-icon {
    width: 15px;
    position: absolute;
    top: -7px;
    right: -7px;
}

#judge-evaluation-criteria {
    margin-top: 10px;
}

#judge-evaluation-criteria span.help-text {
    font-style: italic;
    font-size: 9pt;
    margin: 5px 0 0 20px;
}

table.judgingForm {
    margin-bottom: 1em;
    margin-top: 0.5em;
}

.judging_comments {
    clear: both;
}

.judging_comments .comment {
    width: 49%;
    display: inline-block;
}

.judging_comments .comment .c-Comment__commentor, .judging_comments .comment .c-Comment {
    display: inline-block;
    vertical-align: top;
}

.judging_comments .comment .c-Comment__commentor {
    width: 30%;
}

.judging_comments .comment .c-Comment {
    width: 70%;
}

.judging_comments h2 {
    margin: 0.5em 0;
}

.submitStatus {
    margin-top: 1em;
}

.submitStatus.error {
    color: #900;
}

#advanceDecision {
    width: 100%;
    font-size: 14px;
    color: black;
}


#proposalPicker_proposalsContainer h4 {
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 0;
}

#proposalPicker_proposalsContainer .propbox ul {
    margin-left: 0;
    padding-left: 0;
    list-style: none;
}

#proposalPicker_proposalsContainer .propbox li {
    list-style: none;
    margin-left: 0;
    padding-left: 0;
    background: none;
}

#proposalPicker_proposalsContainer .propbox .row2 {
    width: 650px;
}

#proposalPicker_proposalsContainer .higlightText1,
#proposalPicker_contestsContainer .higlightText1 {
    background: #fdff62;
    font-style: italic;
}

#proposalPicker_proposalsContainer .c-Tooltip .higlightText1,
#proposalPicker_contestsContainer .c-Tooltip .higlightText1 {
    background: none;
    text-decoration: none;
    font-style: normal;
}

.box_tooltip {
    display: inline;
    position: relative;
}

.box_tooltip:hover:after {
    background: #333;
    background: rgba(0, 0, 0, .8);
    border-radius: 5px;
    bottom: 26px;
    color: #fff;
    content: attr(title);
    left: 20%;
    padding: 5px 15px;
    position: absolute;
    z-index: 98;
    width: 220px;
}

.box_tooltip:hover:before {
    border: solid;
    border-color: #333 transparent;
    border-width: 6px 6px 0 6px;
    bottom: 20px;
    content: "";
    left: 50%;
    position: absolute;
    z-index: 99;
}

.greyed_out {
    color: #aaa;
}

.proposalPicker_contestsScrollContainer {
    height: 300px;
    overflow-y: scroll;
}

div.selected {
    background-color: #eaf2fb;
}

.wizardSelectedOptionsInfo li {
    font-weight: normal !important;
}

.modelSelector {
    background: #e8e7dc;
    padding: 10px;
}

#portlet-preferences h2 {
    margin: 1em 0;
}

/* fix problem with the tooltip library, where tooltips overlay the phase names */
.contestbar .phaseLinkContainer div.c-Tooltip {
    top: -102px !important;
}

.dd-options li {
    margin-left: 0 !important;
    padding: inherit !important;
    background: none !important;
}

/* Impact tab */

#impact table td, #impact table th {
    padding: 10px;
    max-width: 200px;
}

#impact th.blue-text {
    color: #30a3fb;
}

#impact .blue-bg {
    font-size: 13px;
    color: white;
    background: #30a3fb;
    border: 2px white solid;
}

#impact .impact-value {
    font-weight: bold;
    text-align: center;
    border-left: 2px black solid;
}

#impact :not(tr:last-child) .impact-value {
    border-right: 2px black solid;
}

#impact-series-detail table#edit-table tr {
    border-left: 2px black solid;
}

#impact-series-detail table#edit-table thead th,
#impact table#impact-summary,
#impact-series-detail table#edit-table {
    border-left: 2px solid black;
    border-right: 2px solid black;
}

#impact-series-detail table#edit-table tbody,
#impact-series-detail table#edit-table tbody,
#impact table#impact-summary {
    border-bottom: 2px solid black;
}

#impact tr.selected {
    background-color: #e0e0e0;
}

#impact tr select {
    margin: 0;
    width: 202px;
}

#impact table#impact-summary tbody {
    max-height: 350px;
    overflow: auto;
}

#impact input {
    width: 30px;
    text-align: center;
    padding: 5px;
}

#impact input.error {
    border: 2px solid red;
}

#impact td.shaded-bg {
    text-align: center;
}

#impact table#impact-series-edit span {
    margin: 0 auto;
    padding-left: 5px;
}

#impact-chart {
    display: block;
    width: 500px;
    margin: 0 auto 20px auto;
}

#impact-series-detail div#new-series {
    vertical-align: middle;
    display: table-cell;
}

#impact-series-detail div#new-series select {
    margin-right: 15px;
    width: 21.5%;
    height: 32px;
}

#impact-series-detail div#c-Header {
    padding-top: 15px;
    vertical-align: middle;
    display: table-cell;
}

#impact-series-detail div#c-Header h3 {
    margin-right: 10px;
    display: inline-block;
}

#impact div#sgrip {
    bottom: -5px;
    left: 50%;

    width: 10px;
    height: 10px;
    background-color: #ffffff;
    border: 1px solid #000000;
}

#impact table .sector {
    padding-left: 20px;
}

#impact table .model.total {
    background-color: #66af45;
}

#impact table .bold {
    font-weight: bold;
}
#impact table .italic {
    font-style: italic;
}

#impact-summary tr {
    opacity: 1.0;
}

.subcontests {
    padding-bottom: 30px;
    padding-top: 20px;
}

tr.impact-series-clickable:hover,
tr#impact-new-series-row:hover {
    background-color: #eaf2fb;
}

.subcontests .title {
    font-size: 16px;
    color: #66af45;
}

.subcontests ul {
    margin-left: 0;
    width: 100%;
    overflow: hidden;
}

.subcontests li {
    margin-left: 0;
    width: 49%;
    line-height: 1.5em;
    float: left;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
}

.red-text {
    color: red;
}

span.stars, span.stars span {
    display: block;
    background: url(/images/stars.png) 0 -16px repeat-x;
    width: 80px;
    height: 16px;
}

span.stars span {
    background-position: 0 0;
}

.impact-value {
    background-color: #b9b8b3;
}

.sector.total {
    background-color: #666666;
}

.sector.model {
    background-color: #66af45;
}

.modelSettingsSelectBox input[type=radio] {
    margin-right: 5px;
}

.modelsSelectBox input[type=radio] {
    margin-right: 5px;
}

.impactComments {
    padding-top: 30px;
}

.impactComments textarea {
    float: right;
    border: 1px solid #888888;
    font-size: 16px;
    width: 540px;
    padding: 5px;
    height: 100px;
}

.impactComments h3 {
    font-size: 22px;
    margin-bottom: 13px;
    color: #404040;
}
