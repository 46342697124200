$c-Comment__special__highlight: $t-Color__alert--light !default;

$c-Comment__box__border: 2px solid $t-Color__gray--medium-500 !default;

$c-Comment__commentor__backgroundImage: url(/images/pattern-6x6.png) !default;
$c-Comment__commentor__textColor: black !default;

$c-Comment__threads__list__tr__border--bottom: 1px solid $t-Color__gray--medium-500 !default;

.c-Comment { //.the-comment
  width: 693px;
  padding: 28px;
  position: relative;
  line-height: 22px;
  color: $t-Color__gray--dark-800;

  p {
    margin: 0 0 10px 0;
  }
  img {
    max-width: 650px;
  }
}

.c-Comment__box { //.commentbox
  height: 178px;
  width: 788px;
  border: $c-Comment__box__border;
  margin-bottom: 25px;

  &.discussion {
    width: 700px
  }
}

.c-Comment__actions { //.commentsActions
  margin-top: 15px;
  text-align: right;
}

.c-Comment__list { //.comments
  font-size: 15px;

  tr.odd td {
    background-color: $t-Color__tan;
  }

  td {
    border-bottom: 3px solid white;
  }
}

.c-Comment.spam, .comments tr.odd td.c-Comment.spam{
  background-color: $c-Comment__special__highlight;
}

.c-Comment__commentor { //.commentor
  background-image: $c-Comment__commentor__backgroundImage;
  width: 212px;
  padding: 16px 20px 26px 16px;
  line-height: 19px;
  color: $c-Comment__commentor__textColor;

  a {
    font-weight: $t-Typography__fontWeight--bold;
  }

  img {
    margin-bottom: 14px;
  }

  span {
    font-weight: $t-Typography__fontWeight--bold;
  }
}

.c-Box .c-Comment {
  width: 100%;
  padding: 0;
}
