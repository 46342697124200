$p-Contests__userslist__a__fontWeight: 700 !default;

$p-Contests__userslist__td--g__background: url(/images/pattern-tbl-6x6.png) !default;
$p-Contests__usersFilter__paddingRight: 0px !default;

/* CollectionCardsView */

.c-Headline .AdvancedView {
  float: right;
  height: 34px;
  line-height: 34px;
  margin-right: 55px;
  font-size: 12px;
  font-weight: $t-Typography__fontWeight--bold;
  text-transform: uppercase;

  > div {
    float:left;
    padding-left: 35px;
  }

  .outline {
    background: url(/images/icon-list_outline-off.png) no-repeat 6px 8px;

    &.selected {
      background: url(/images/icon-list_card-on.png) no-repeat 6px 9px;
    }
  }
}

.c-Headline .showOptions{
  margin-right: 40px;
}

.contests-container {
  background-color: $t-Color__primary__gray--light;
  padding-top:15px;
  padding-left:35px;
  overflow:hidden;
}

.c-ontology-selector {

  height: 50px;

  .c-arrow {
    float:left;
    border-left: 30px solid transparent;
    border-right: 30px solid transparent;
    border-bottom: 32px solid transparent;
    margin-right: 50px;

    &.selected {
      border-bottom: 32px solid $t-Color__primary__gray--light;
      background-color:white;
    }
  }

  a {
    font-weight: bold;
    margin-right: 50px;
    &.selected {
      color: $t-Color__gray--dark-600;
    }
  }

  .filter {
    margin-right: 65px;
  }
}

.c-breadcrump {
  font-size: 14px;
  background-color: $t-Color__primary__gray--light;
  padding: 17px;
  padding-bottom: 0;
  padding-left: 20px;

  span {
    color: $t-Color__gray--dark-600
  }

  a {
    color: $t-Color__primary;
    text-decoration: none;
  }
}

.c-display-selector {

  background-color: $t-Color__primary__gray--light;
  height: 65px;

  .show-active {
    float: right;
    text-align: right;
    position: relative;
    font: 12px "Lucida Grande", Arial, sans-serif;
    margin-right: 65px;
    margin-top: 25px;
    padding: 0 16px 0 0;
  }

  .view{
    > div {
      width:70px;
      text-align: right;
      padding-right: 5px;
      margin: 15px;
      float: left;
      height: 34px;
      line-height: 34px;
      font-size: 12px;
      font-weight: $t-Typography__fontWeight--bold;
      text-transform: uppercase;

      &.selected {
        color: $t-Color__gray--dark-600;
        border: solid 1px $t-Color__primary;
        border-radius: 3px;
        margin:14px;
      }
    }

    .grid {
      background: url(/images/icon-grid_view-off.png) no-repeat 8px 8px;
      &.selected {
        background: url(/images/icon-grid_view-off.png) no-repeat 8px 8px white;
      }
    }

    .list {
      background: url(/images/icon-list_view-off.png) no-repeat 6px 8px;
      &.selected {
        background: url(/images/icon-list_view-off.png) no-repeat 6px 8px white;
      }
    }
  }
}


.c-description {
  margin-right: 400px;
  color: $t-Color__gray--dark-600;
  font-size: 15px;
  line-height: 24px;
}

/* NOT CollectionCardView */

/* columns  ------------- */
.contest-left {
  float: left;
  width: 500px;
}

.contest-right {
  float: left;
  width: 484px;
}

.p-Contests__viewSelector {
  background: $t-Color__tan;

  .btn {
    font-size: .75rem;
    text-transform: uppercase;
    font-weight: $t-Typography__fontWeight--bold;
  }
}

.list a {
  width: 100%;
}

.grid a {
  width: 100%;
}

.filter input {
  width: 170px;
  height: 27px;
  padding: 1px 0 0 8px;
  border: 1px solid $t-Color__gray--medium-500;
  font: 12px "Lucida Grande", Arial, sans-serif;
}

.users-pagination {
  float: left;
  width: 100%;
  border-bottom: 1px solid $t-Color__gray--medium-500;
  padding-bottom: 40px;
  padding-top: 40px;
}

.p-Contests__featuredSeparator {
  margin: 0 auto 1.5rem auto;
  width: 75%;

  @include media-breakpoint-up(lg) {
    margin-top: 1.5rem;
    margin-bottom: 3rem;
    width: 50%;
  }
}

/* the table with rows of users  */
.userslist {
  clear: both;
  margin-top: -4px;
}

.userslist td {
  height: 30px;
  padding: 11px 10px 0;
  font-size: 14px;
  line-height: 20px;
  color: $t-Color__gray--dark-800;
  border-bottom: 1px solid $t-Color__tan;
}

.userslist a {
  font-weight: $p-Contests__userslist__a__fontWeight;
}

.userslist tr.top td {
  height: 61px;
  padding-top: 15px;
}

.userslist td.g {
  background: $p-Contests__userslist__td--g__background;
}

.userslist.featured td {
  background-color: $t-Color__tan;
}

.userslist tr.feat-bot {
  border-bottom: 1px solid $t-Color__primary__gray;
}

//TODO: what exactly are these?
#contestsFilterClear {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 5px;
  right: 5px;
  display: block;
}

//contest outline view
.accordion, .accordion li {
  list-style: none;
}

.nothingFound {
  text-align: center;
  padding: 30px;
  text-overflow: ellipsis;
  width: 850px;
  color:$t-Color__gray--dark-600
}
