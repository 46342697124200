.c-PreviousMessage {
  color: $t-Color__primary--darkest;
  border-left: 1px solid ;
  margin-left: 10px;
  margin-top: 2%;
  padding-left: 5px;
}

.c-PreviousMessage__header {
  color: $t-Color__secondary--darkest;
  opacity: 0.8;
  font-size: smaller;
}

//Maximum of 5 levels of indentation
.c-PreviousMessage .c-PreviousMessage .c-PreviousMessage .c-PreviousMessage .c-PreviousMessage {
  color: $t-Color__primary--darkest;
  border-left: none ;
  margin-left: 0px;
  margin-top: 1%;
  padding-top: 2%;
  padding-left: 0px;
  position: relative;
  &:after {
    content:"";
    background: $t-Color__primary__gray;
    opacity: 0.8;
    position: absolute;
    top: 0;
    left: 10%;
    height: 1px;
    width: 70%;
  }
}
