@import "../../variables/t-base";
@import "bootstrap/functions";
@import "bootstrap/variables";

$c-Header__menu__li__a__textColor: $t-Color__primary !default;
$c-Header__menu__li__a__fontWeight: $t-Typography__fontWeight--bold !default;

$c-Header__menu__right__li__a__fontWeight: $t-Typography__fontWeight--bold !default;

$c-Header__navItemGray--textColor: $t-Color__gray--dark-700 !default;
$c-Header__navItemGray--hoverColor: black !default;

$cb-navbar__hasBoxShadow: false !default;
$cb-navbar__activeBoxShadow: none !default;
$cb-navbar__background: $t-Color__tan !default;
$cb-navbar__searchIconColor: $t-Color__primary !default;

// local vars
$_navbar__height: 67px;
$_navbar__boxShadow: 0 5px 2px -2px rgba($t-Color__gray--dark-600, .7);

@mixin navbar-expanded {
  @include media-breakpoint-up(lg) {
    @content;
  }
}

@mixin navbar-collapsed {
  @include media-breakpoint-down(md) {
    @content;
  }
}

.nav li,
.navbar li {
  margin-left: 0;
}

.navbar-nav {
  .nav-link {
    @include navbar-expanded {
      line-height: $_navbar__height;
      padding-top: 0;
      padding-bottom: 0;
    }

    font-weight: $c-Header__menu__li__a__fontWeight;
  }

  .nav-item {
    @include navbar-expanded {
      &.cb-nav-item-multi-line {
        height: $_navbar__height;
        .nav-link {
          line-height: normal;
        }
      }
    }
  }

  .cb-nav-item-icon {

    .nav-link {
      @include navbar-expanded {
        height: 67px;
        line-height: 1;
        padding-top: 10px;
        text-align: center;
      }
    }

    .nav-icon {
      //Hide on small screens, only show in expanded navbar
      display: none;
      height: 30px;
      width: 30px;

      @include navbar-expanded {
        display: initial;

        & + .cb-nav-text {
          display: block;
          margin-top: 5px;
        }
      }
    }
  }

  .cb-nav-item-image {
    .nav-link {
      > img {
        height: 25px;
        width: 25px;
        border: 1px solid $t-Color__gray--dark-600;
      }

      .badge {
        padding: 2px 5px;
        font-weight: 400;
        @include navbar-expanded {
          position: absolute;
          top: 14px;
          right: 10px;
        }
      }
    }
  }

  .cb-nav-item-separator-left {
    @include navbar-expanded {
      border-left: 1px solid $t-Color__gray--medium-500;
      margin-left: $spacer / 2;
      padding-left: $spacer / 2;
    }
  }
}

.cb-nav-pages {
  @include navbar-expanded {
    margin-left: $spacer;

    .nav-item {
      border-left: 1px solid $t-Color__gray--medium-500;

      &.dropdown.show {
        border-left-color: white;
      }
    }
  }
}

.navbar-light .navbar-nav {
  .nav-link {
    color: $c-Header__menu__li__a__textColor;

    &:hover {
      color: black;
    }
  }

  .nav-item:not(.show) { // don't override shown dropdowns
    .c-Icon__search {
      fill: $cb-navbar__searchIconColor;
    }
  }
}

.navbar-light .navbar-nav {
  .active {
    @include navbar-expanded {
      background: white;
      margin-bottom: -1px;
      box-shadow: $cb-navbar__activeBoxShadow;
    }
  }
}


#current-language {
  background: none;
}


.navbar-nav.cb-nav-pages .nav-link {
  @include navbar-expanded {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.cb-nav-text {
  font-weight: $t-Typography__fontWeight--bold;
}

.navbar-light .navbar-nav .cb-nav-item-gray {
  @include navbar-expanded {
    .nav-link {
      color: $c-Header__navItemGray--textColor;

      &:hover {
        color: $c-Header__navItemGray--hoverColor;
      }
    }
  }
}


.cb-nav-item-sm {
  @include navbar-expanded {
    text-transform: uppercase;
    font-size: 0.625rem;
    font-weight: 700;
  }

  //Reset for dropdown menus, which are children of the nav-item
  .dropdown-menu {
    font-size: initial;
    font-weight: initial;
    text-transform: initial;
  }
}

.cb-navbar-background {
  background: $cb-navbar__background;
  border-bottom: 1px solid $t-Color__gray--medium-500;

  @if $cb-navbar__hasBoxShadow {
    box-shadow: $_navbar__boxShadow;
  }
}

.cb-navbar-background-white {
  background: white;
}

.cb-navbar-button {
  display: flex;
  align-items: center;
  border-left: 0 !important;

  > .btn {
    @include navbar-collapsed {
      padding: .5rem 0;
      background: none;
      color: var(--primary);
      border: 0;
      box-shadow: none;
      font-size: 15px;
    }
  }
}
