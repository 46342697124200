$c-ContestCard__meta__flag__capitalization: $b-Typography__capitalization--capitalize !default;
$c-ContestCard__meta__flag__padding: 0 15px !default;
$c-ContestCard__meta__flag__height: 30px !default;
$c-ContestCard__meta__flag__fontSize: 15px !default;
$c-ContestCard__meta__flag__marginTop: 0 !default;
$c-ContestCard__meta__flag__isGradient: true !default;
$c-ContestCard__meta__flag__background--color: $t-Color__secondary !default;
$c-ContestCard__meta__flag__textColor--color: white !default;
$c-ContestCard__meta__flag__background--grey: $t-Color__primary__gray--light !default;
$c-ContestCard__meta__flag__textColor--grey: $t-Color__gray--dark-600 !default;
$c-ContestCard__meta__flag__fontWeight: $t-Typography__fontWeight !default;
$c-ContestCard__meta__count__textColor: $t-Color__gray--dark-600;

$c-ContestCard__text__height: 104px !default;
$c-ContestCard__text__title__textColor: $t-Color__primary !default;
$c-ContestCard__text__title__textColor--hover: $t-Link__color--hover !default;

$c-ContestCard__background: white !default;
$c-ContestCard__border: 1px solid $t-Color__gray--medium-500 !default;
$c-ContestCard__border__radius: 8px !default;
$c-ContestCard__border__bottom: null !default;
$c-ContestCard__text__textColor: $t-Color__gray--dark-700;
$c-ContestCard__shadow--hover:  0 0 10px  $t-Color__gray--medium-500;

.c-ContestCard {
  width: 200px;

  border: $c-ContestCard__border;
  @if $c-ContestCard__border__bottom != null {
    border-bottom: $c-ContestCard__border__bottom;
  }
  border-radius: $c-ContestCard__border__radius;

  background: $c-ContestCard__background;

  &.backgroundLight {
    background: white;
  }

  &:hover {
    box-shadow: $c-ContestCard__shadow--hover;
  }

  margin-bottom: 24px;
  float: left;
  position: relative;
  height: 400px;
  margin-right: 35px;
}

.c-ContestCard__image {
  float: left;
  width: 200px;

  img {
    height: 200px;
    width: 200px;
    border-top: $c-ContestCard__border;
    border-bottom: $c-ContestCard__border;
  }
}

.c-ContestCard__text {
  float: left;
  width: 184px;
  font-size: 14px;
  line-height: 20px;
  padding: 8px;
  overflow: hidden;
  color: $c-ContestCard__text__textColor;
  height: $c-ContestCard__text__height;
}

.c-ContestCard__text__title {
  margin-bottom: 8px;
  font-size: 18px;
  color: $c-ContestCard__text__title__textColor;
  line-height: 22px;
  overflow: hidden;

  &:hover {
    color: $c-ContestCard__text__title__textColor--hover;
    text-decoration: none;
  }
}

.c-ContestCard__meta__count {
  color: $c-ContestCard__meta__count__textColor;
  float: left;
  height: 26px;
  width: 184px;
  padding: 8px;
  font-size: 14px;


  .prop {
    display: inline;
    padding-right: 10px;
    text-transform: lowercase;
  }

  .comm {
    display: inline;
  }


  strong {
    color: $c-ContestCard__text__title__textColor;
  }
}

.c-ContestCard__meta__flags {
  height: 20px;
  display: inline-block;
  margin: 8px;
  float: left;

  div {
    padding: 2px;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 3px;
    line-height: 14px;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    text-transform: $c-ContestCard__meta__flag__capitalization;
  }
}


.c-ContestCard__meta__flag--grey {
  background: $c-ContestCard__meta__flag__background--grey;
  color: $c-ContestCard__meta__flag__textColor--grey;
}

.c-ContestCard__meta__flag--color {
  background: $c-ContestCard__meta__flag__background--color;
  color: $c-ContestCard__meta__flag__textColor--color;
}
