
$c-Badge__textColor: white !default;
$c-Badge__title__textColor: $t-Color__gray--dark-800 !default;

//TODO: this is page-specific -> move to _p-userprofile.scss
.c-Badge__container {
  text-align: center;
  width: 490px;
  float: right;
}

%c-Badge {
  position: relative;
  float: left;
  &.badge-1 {
    background-image: url(/images/ribbons/contest-badge-big-1.png);
  }

  &.badge-2 {
    background-image: url(/images/ribbons/contest-badge-big-2.png);
  }

  &.badge-3 {
    background-image: url(/images/ribbons/contest-badge-big-3.png);
  }
}

.c-Badge--big {
  @extend %c-Badge;
  width: 183px;
  height: 161px;
  background-size: 183px 161px;
  margin: 0 30px;
}

.c-Badge--small {
  @extend %c-Badge;
  width: 68px;
  height: 60px;
  background-size: 68px 60px;
  margin: 0 10px;
}

%c-Badge__title {
  font-weight: $t-Typography__fontWeight--bold;
  color: $c-Badge__title__textColor;
  position: absolute;
}

.c-Badge__title--small {
  @extend %c-Badge__title;
  font-size: 6px;
  top: 15px;
  left: 24px;
  width: 20px;
}

.c-Badge__title--big {
  @extend %c-Badge__title;
  font-size: 19px;
  top: 40px;
  left: 52px;
  width: 80px;
}

%c-Badge__year {
  color: $c-Badge__textColor;
  font-weight: $t-Typography__fontWeight--bold;
  position: absolute;
  transform: rotate(323deg);
}

.c-Badge__year--small {
  @extend %c-Badge__year;
  font-size: 9px;
  left: 44px;
  top: 36px;
}

.c-Badge__year--big {
  @extend %c-Badge__year;
  font-size: 11px;
  left: 124px;
  top: 106px;
}

%c-Badge__text {
  font-weight: $t-Typography__fontWeight--bold;
  color: $c-Badge__textColor;
  position: absolute;
  transform: rotate(396deg);
 }

.c-Badge__text--small {
  @extend %c-Badge__text;
  font-size: 9px;
  left: 10px;
  top: 36px;

}

.c-Badge__text--big {
  @extend %c-Badge__text;
  width: 49px;
  font-size: 8px;
  left: 20px;
  top: 101px;
}
