$c-Form__selectBox__background: url(/images/butt_arrowbdr.png) no-repeat right !default;
$c-Form__selectBox__textColor: #666 !default;
$c-Form__selectBox__span__textColor: $t-Color__accent !default;

$c-Form__selectBox__input__background: url(/images/butt_arrowbdr.png) no-repeat right !default;
$c-Form__selectBox__input__textColor: #666 !default;
$c-Form__selectBox__input__span__textColor: $t-Color__accent !default;

.c-Form__selectbox { //.selectbox
  padding: 0 44px 0 9px;
  width: 100%;
  height: 35px;
  display: block;
  background: $c-Form__selectBox__background;
  font-size: 15px;
  font-style: italic;
  color: $c-Form__selectBox__textColor;
  border: none;
  cursor: pointer;

  span {
    font-size: 11px;
    font-style: normal;
    font-weight: $t-Typography__fontWeight--bold;
    color: $c-Form__selectBox__span__textColor;
  }
}

.c-Form__selectbox__new { //.selectbox1
  overflow: hidden;
}

input.c-Form__selectbox, input[type='text'].c-Form__selectbox { //input.selectbox
  width: 100%;
  height: 35px;
  display: block;
  background: $c-Form__selectBox__input__background;
  font-size: 17px;
  font-style: italic;
  color: $c-Form__selectBox__input__textColor;
  border: none;
  cursor: pointer;
  padding: 0 35px 0 9px;

  span {
    font-size: 13px;
    font-style: normal;
    font-weight: $t-Typography__fontWeight--bold;
    color: $c-Form__selectBox__input__span__textColor;
  }
}
