#content {
  padding-top: 25px;
  padding-bottom: 25px;
  position: relative;
}

#c-Footer__menu__right {
  float: right;
  min-width: 500px;
  height: 44px;
  position: relative;
}

#c-Footer__menu__right p {
  margin: 0 0 0 0;
}
