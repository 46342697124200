.points-container {
  margin: 0 auto;
  width: 123px;
  height: 123px;
  position: relative;

  .fill, .globe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  .fill {
    background-color: $t-Color__primary;
    transition: background-color 2s ease;
  }

  .globe {
    background: url(/images/points/globus.png) no-repeat;
    background-size: cover;
    height: 123px;
  }
}

p.arrow-down {
  background: url(/images/points/arrow-down.png) no-repeat;
  background-size: cover;
  width: 35px;
  height: 52px;
  margin: 10px auto 13px auto;
  position: relative;
}

td.rightBranch.small .points-container, td.rightBranch.small .arrow-down {
  left: -4.15%;
}

td.leftBranch.small .points-container, td.leftBranch.small .arrow-down {
  left: 4.15%;
}

.pointType {
  table-layout: fixed;

  input {
    max-width: 100%;
  }

  label.username-picker {
    display: block;
    margin: 1.5em 0;
  }

  p.percentage {
    text-align: center;
    position: relative;
    top: -130px;
    font-size: 2em;
  }
  p.info {
    margin-bottom: 0;
    margin-right: 10px;
  }

  td.leftBranch {
    background: url(/images/points/arrow-left.png) 69.5% 0;
  }
  td.leftBranch.large {
    background-position: 62.5% 0;
  }
  td.rightBranch.large {
    background-position: 37.5% 0;
  }
  td.rightBranch.small {
    background-position: 0 0;
  }
  td.leftBranch.small {
    background-position: 100% 0;
  }
  td.rightBranch {
    background: url(/images/points/arrow-right.png) 30.5% 0;
  }
  td.leftBranch, td.rightBranch {
    background-repeat: no-repeat;
    position: relative;
    top: -50px;
    padding-top: 80px;

    td.leftBranch, td.rightBranch {
      padding-left: 10px;
      padding-right: 10px;
      top: -30px;
      padding-top: 50px;
      background-size: 35%;

      p.percentage {
        top: -100px;
      }
    }
    td.leftBranch {
      background-position: 60% 0;
    }
    td.rightBranch {
      background-position: 40% 0;
    }
    td {
      td.leftBranch {
        background-position: 30% 0;
      }
      td.rightBranch {
        background-position: 70% 0;
      }
    }
  }
  ul li {
    padding: 5px 0;
    background: none;
  }

  .highlighted {
    color: $t-Color__secondary;
    font-weight: $t-Typography__fontWeight--bold;
    text-align: center;
  }
  span.highlighted {
    padding-right: 5px;
  }
  .deleteListItem {
    float: right;
    margin: 8px 18px 0 10px;
    padding: 2px 6px;
    background: $t-Color__primary;
    border-radius: 3px;
    color: white;
    cursor: pointer;
  }
  .error {
    font-weight: $t-Typography__fontWeight--bold;
    color: $t-Color__alert;
  }
}

ul.userDistributionInputs {
  list-style-type: none;

  li {
    padding: 0;
    margin: 0;
    background: none;
    clear: both;
  }

  li.header {
    padding-bottom: 10px;
    padding-top: 15px;
  }

  li.error {
    padding-top: 10px;
  }

  .header .percentage {
    padding-left: 30px;
  }

  .header .name {
    padding-left: 39px;
  }
}

.totalRow .percentage {
  width: 75px;
  margin-right: 2px;
  display: inline-block;
  text-align: center;
}

.totalRow .percentage.smaller {
  width: 51px;
  margin-right: 6px;
  margin-left: 11px;
}

ul.subproposals {
  list-style-type: none;

  li {
    > a {
      display: inline-block;
      width: 80%;
    }
    > span {
      display: inline-block;
      vertical-align: top;
    }
  }
}

.percentageInput {
  input {
    width: 50px;
    margin-left: 5px;
    margin-right: 15px;
    float: none;
  }
  span, input, .input {
    display: inline-block;
    vertical-align: baseline;
  }
  span.input {
    text-align: center;
    height: 24px;
    width: 75px;
    margin-right: 5px;
    margin-left: 0;
    float: none;
  }
}

#help-text {
  margin: 1.5em 0;
  padding: 15px;
  background: $t-Color__primary__gray--light;
  border-radius: 10px;
}

#expand-help-text {
  display: inline-block;
  cursor: pointer;

  width: 31px;
  height: 31px;
  line-height: 25px;
  vertical-align: middle;

  font-weight: 700;
  text-align: center;
  font-size: 20px;

  color: black;
  border: 2px solid $t-Color__primary;
  border-radius: 50%;
}
