//resets browser stylesheets to a common default

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, font, img, ins, kbd, q, s, samp,
small, strike, strong, b, i, sub, sup, tt, var, caption,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend, input,
table, tbody, tfoot, thead, tr, th, td {
  padding: 0;
}

//= Defaults for tables
table {
  width: 100%;
}

td {
  vertical-align: top;
}

td, th {
  text-align: left;
  line-height: normal;
}

//= Defaults for lists
ol, ul {
  margin-left: 0;

  .cke_editable & {
    margin-left: 15px;
  }
}

ol li {
  margin-left: 20px;
}

ul li {
  margin-left: 11px;
}

//= Defaults for images
img {
  max-width: 100%;

  &.media-object {
    // compatibility with bootstrap media object
    max-width: initial;
  }
}
