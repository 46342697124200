/** Search page **/
.result {
  margin: 10px 0 5px 0;
  border-bottom: 1px solid #C5C5C5;
}

.result.blu {
  background: #F3F8FA;
}

.result_title {
  float: left;
  display: inline;
  padding: 6px 25px 0 0;
  margin: 0;
}

.result_locale {
  float: right;
  background: $t-Color__secondary;
  color: white;
  text-align: center;
  padding: 4px 10px 4px 10px;
  margin: 4px 10px 0;
  font-size: 13px;
  font-weight: $t-Typography__fontWeight--bold;
  line-height: normal;
  border-radius: 5px;
}

.clear {
  clear: both;
  height: 0;
}
