//Variables

$b-Typography__capitalization--none: none !default;
$b-Typography__capitalization--capitalize: uppercase !default;

$b-Typography__capitalization__h1: $b-Typography__capitalization--none !default;
$b-Typography__capitalization__h2: $b-Typography__capitalization--none !default;

$t-Typography__fontWeight__h1: $t-Typography__fontWeight !default;
$t-Typography__fontWeight__h2: $t-Typography__fontWeight--bold !default;
$t-Typography__fontWeight__h3: $t-Typography__fontWeight--bold !default;
$t-Typography__fontWeight__h4: $t-Typography__fontWeight !default;
$t-Typography__fontWeight__h5: $t-Typography__fontWeight !default;
$t-Typography__fontWeight__h6: $t-Typography__fontWeight !default;

$b-Typography__textColor: initial !default;
$b-Typography__textColor__h1: $t-Color__gray--dark-800 !default;

body {
  font-size: 15px;
  font-weight: $t-Typography__fontWeight;
  color: $b-Typography__textColor;
}

h1 {
  font-weight: $t-Typography__fontWeight__h1;
  color: $b-Typography__textColor__h1;
  line-height: 1.1;
  text-transform: $b-Typography__capitalization__h1;
}

h2 {
  font-weight: $t-Typography__fontWeight__h2;
  text-transform: $b-Typography__capitalization__h2;
}

h3 {
  font-weight: $t-Typography__fontWeight__h3;
}

h4 {
  font-weight: $t-Typography__fontWeight__h4;
}

h5 {
  font-weight: $t-Typography__fontWeight__h5;
}

h6 {
  font-weight: $t-Typography__fontWeight__h6;
}
