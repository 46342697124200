$p-community__left__lineHeight: 21px !default;
$p-community__colab__th__background: #E7F1F5 !default;
$p-community__colab__th__textColor: $t-Color__gray--dark-800 !default;
$p-community__commList__marginTop: 20px !default;
$p-community__commList__h2__paddingBottom: 0px !default;
$p-community__commList__h2__borderBottom: none !default;
$p-community__commList__h2__fontWeight: $t-Typography__fontWeight__h2 !default;
$p-community__discHead__width: 680px !default;
$p-community__infoLeft__fontStyle: italic !default;
$p-community__infoLeft__capitalization: $b-Typography__capitalization--none !default;
$p-community__infoLeft__fontSize: initial !default;
$p-community__infoLeft__fontWeight: $t-Typography__fontWeight !default;
$p-community__infoLeft__paddingTop: 0px !default;
$p-community__infoLeft__span__textColor: $t-Color__secondary !default;
$p-community__infoLeft__span__fontSize: 32px !default;
$p-community__infoLeft__span__paddingRight: 5px !default;
$p-community__infoLeft__span__fontWeight: $t-Typography__fontWeight--bold !default;

/** COMMUNITY **/

/* community - discussions */

.comm_list {
  line-height: normal;
  margin-top: $p-community__commList__marginTop;
}

.comm_list h2 {
  font-size: 20px;
  color: $t-Color__gray--dark-800;
  margin-bottom: 18px;
  padding-bottom: $p-community__commList__h2__paddingBottom;
  border-bottom: $p-community__commList__h2__borderBottom;
  font-weight: $p-community__commList__h2__fontWeight;

}

.comm_list h3 {
  font-size: 14px;
}

.comm_list ul {
  list-style: none;
}

.comm_list li {
  font-size: 17px;
  margin-top: 10px;
}

.comm_list li.c {
  font-weight: $t-Typography__fontWeight--bold;
}

.colab .colabRow {
  border-bottom: 1px solid #D2D1D0;
}

.colab .colabRow td {
  padding: 10px 0 10px
}

.colab {
  margin-top: 40px;
}

.colab .question {
  width: 405px;
}

.colab .replies {
  width: 60px;
}

.colab .date {
  width: 60px;
}

.colab thead td {
  padding: 0;
  font-size: 15px;
}

.colab th.Hdr, .colab .ColGrpHdrRow th {
  height: 27px;
  background: $p-community__colab__th__background;
  border-top: 1px solid #A7D7FB;
  border-bottom: 1px solid #9CBCCA;
  color: $p-community__colab__th__textColor;
  padding-top: 10px;
  padding-left: 5px;
}

/* community - members */

.comm_member {
  margin: 40px 0;
}

.comm_member table.colab {
  width: 100%;
  margin-top: 0;
}

.member table.colab {
  width: 100%;
  margin-top: 0;
  margin-bottom: 20px;
}

.comm_member td.r {
  text-align: right;
}

.comm_member td.b {
  font-weight: $t-Typography__fontWeight--bold;
}

.comm_member .memdiv {
  background: $t-Color__gray--dark-600;
  height: 4px;
  margin: 7px 0 10px 0;
}

#main .comm_member h2 {
  margin: 0;
}

.hidden {
  display: none;
}

/** PAGER **/

.pager td {
  vertical-align: bottom;
}

.paginatorControls {
  padding-left: 20px;
}

.pager {
  margin: auto;
  width: 600px;
  height: 20px;
  text-align: right;
  padding-top: 45px;
}

/** DISCUSSIONS */
#main {
  float: left;
  width: 680px;
}

.right_col {
  float: left;
  width: 200px;
  margin: 0 0 0 50px;
}

.right_col.v2 {
  margin-top: 8px;
}

#main p {
  line-height: 21px;
}

#main h2 {
  margin: 15px 0 7px 0;
}
