$c_ControlsOverlay__backgroundColor: rgba($t-Color__primary__gray--light, .95) !default;

.c-ControlsOverlay {
  background-color: $c_ControlsOverlay__backgroundColor;

  font-size: 20px;
  line-height: 29px;
  border-top: 2px solid $t-Color__primary;

  padding: 25px 15px;
  width: 100%;

  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 99;
}
