$c-CollectionCard__text__title__textColor: $t-Color__primary !default;
$c-CollectionCard__text__title__textColor--hover: $t-Link__color--hover !default;

$c-CollectionCard__background: white !default;
$c-CollectionCard__border: 1px solid $t-Color__gray--medium-500 !default;
$c-CollectionCard__border__radius: 8px !default;
$c-CollectionCard__border__bottom: null !default;
$c-CollectionCard__shadow--hover:  0 0 10px  $t-Color__gray--medium-500;
$c-CollectionCard__background__container__shadow--hover: $c-CollectionCard__shadow--hover;
$c-CollectionCard__circle__background__color: $t-Color__primary;
$c-CollectionCard__circle__textColor: white;
$c-CollectionCard__separator__border-bottom: 2px solid $t-Color__tan;
$c-CollectionCard__text__parent__title__textColor: black;
$c-CollectionCard__text__textColor:  $t-Color__gray--dark-700;
$c-CollectionCard__contests__tag__textColor: $t-Color__gray--dark-600;

.c-CollectionCard__background__container {
  width: 200px;
  background-color: $c-CollectionCard__background;
  margin-bottom: 24px;
  float: left;
  position: relative;
  height: 400px;
  margin-right: 35px;
  border: $c-CollectionCard__border;
  &:hover {
    box-shadow: $c-CollectionCard__background__container__shadow--hover;
  }
  border-radius: $c-CollectionCard__border__radius;
}

.c-CollectionCard__separator {
  border-bottom: $c-CollectionCard__separator__border-bottom;
  width: 184px;
  margin-left: 8px;
  float: left;
}

.c-CollectionCard__circle {
  border-radius: 50%;
  height: 40px;
  background-color: $c-CollectionCard__circle__background__color;
  width: 40px;
  float:left;
  margin-left: 80px;
  color: $c-CollectionCard__circle__textColor;
  text-align: center;
  font-size: 20px;
  line-height: 40px;
  position: relative;
  top: -22px;
}

.c-CollectionCard__plceholder__top {
  height: 80px;
}

.c-CollectionCard__contests__tag {
  color: $c-CollectionCard__contests__tag__textColor;
  font-size: 12px;
  text-align: center;
  position: relative;
  width: 200px;
  float: left;
  top: -15px;
}

.c-CollectionCard { //.contestbox {
  width: 200px;

  border: $c-CollectionCard__border;
  border-radius: $c-CollectionCard__border__radius;

  background: $c-CollectionCard__background;

  &.backgroundLight {
    background: white;
  }

  &:hover {
    box-shadow: $c-CollectionCard__shadow--hover;
  }

  margin-bottom: 24px;
  float: left;
  position: relative;
  height: 400px;
  margin-right: 20px;
}

.c-CollectionCard__image {
  float: left;
  width: 200px;

  img {
    height: 200px;
    width: 200px;
    border-top: $c-CollectionCard__border;
    border-bottom: $c-CollectionCard__border;
  }
}

.c-CollectionCard__text {
  float: left;
  width: 184px;
  font-size: 14px;
  padding: 8px;
  line-height: 19px;
  color: $c-CollectionCard__text__textColor;
  height: 170px;
  overflow: hidden;
}

.c-CollectionCard__text__title {
   color: $c-CollectionCard__text__title__textColor;
   padding: 8px;
   text-align: center;
   float: left;
   width: 184px;
   height: 60px;
   overflow: hidden;
   h3 {
     font-size: 18px;
     line-height: 22px;
   }
   &:hover {
     color: $c-CollectionCard__text__title__textColor--hover;
     text-decoration: none;
   }
 }

.c-CollectionCard__text__parent__title {
  font-size: 13px;
  color: $c-CollectionCard__text__parent__title__textColor;
  width: 200px;
  margin-top: 30px;
  text-align: center;
  float:left;
}




