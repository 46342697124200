//main colors:
$t-Color__primary: #30a3fb !default;
$t-Color__secondary: #66b035 !default;
$t-Color__accent: #e36f0f !default;
$t-Color__auxiliary: #e46970 !default; // 4th color when needed (e.g. ontology)
$t-Color__tan: #efefeb !default;

//lightness variations
$t-Color__primary--light: lighten($t-Color__primary, 24) !default; $__hex: #aad9fd;
$t-Color__secondary--light: lighten($t-Color__secondary, 24) !default; $__hex: #a7da85;

$t-Color__primary--dark: darken($t-Color__primary, 13) !default; $__hex: #0481e1;
$t-Color__secondary--dark: darken($t-Color__secondary, 13) !default; $__hex: #467a25;

$t-Color__primary--darkest: darken($t-Color__primary, 30) !default; $__hex: #03518c;
$t-Color__secondary--darkest: darken($t-Color__secondary, 30) !default; $__hex: #14230b;

//gray variations of the primary color
$t-Color__primary__gray--dark: desaturate($t-Color__primary, 61) !default; $__hex: #719aba;
$t-Color__primary__gray: lighten($t-Color__primary__gray--dark, 11) !default; $__hex: #9cb6c9;
$t-Color__primary__gray--light: lighten($t-Color__primary__gray--dark, 35) !default; $__hex: #eaf0f5;

//grays
$t-Color__gray--light-100: #f9f9f9 !default;
$t-Color__gray--light-200: #eee !default;
$t-Color__gray--light-300: #e0e0e0 !default;
$t-Color__gray--light-400: #d0d0d0 !default;

$t-Color__gray--medium-500: #bababa !default;

$t-Color__gray--dark-600: #888 !default;
$t-Color__gray--dark-700: #575757 !default;
$t-Color__gray--dark-800: #404040 !default;
$t-Color__gray--dark-900: #252525 !default;

//alert and message colors:
$t-Color__success: $t-Color__secondary !default;
$t-Color__alert: #dc3545 !default;
$t-Color__warning: #ffc107 !default;
$t-Color__info: $t-Color__primary__gray !default;

//TODO COLAB-2352: We don't need these anymore once we remove c-Alert
$t-Color__alert--light: #f2dede !default;
$t-Color__info__background: $t-Color__primary__gray--light !default;
$t-Color__success__background: $t-Color__secondary--light !default;

//standard text colors
$t-Color__text--light: white !default;
$t-Color__text--dark: $t-Color__gray--dark-800 !default;

//outline view TODO: see if we can adapt this to use our colors
$t-Color__outline__level1: #e0dacb !default; // background slevel1
$t-Color__outline__level2: #d1cbbf !default; // background slevel2
$t-Color__outline__level3: #c1bcb2 !default; // background slevel3
$t-Color__outline__level4: #aaa69f !default; // background slevel4-6

$t-Typography__fontFamily: "proxima-nova", Avenir, Arial, sans-serif !default;
$t-Typography__fontWeight: 400 !default;
$t-Typography__fontWeight--light: 400 !default;
$t-Typography__fontWeight--bold: 700 !default;
$t-Typography__fontSize__h1: 2rem !default;
$t-Typography__fontSize__h2: 1.6875rem !default;
$t-Typography__fontSize__h3: 1.25rem !default;
$t-Typography__fontSize__h4: 1.0625rem !default;
$t-Typography__fontSize__h5: 1rem !default;
$t-Typography__fontSize__h6: 1rem !default;

$t-Border__rounded: true !default;
$t-Border__radius: .3125rem !default;
$t-Border__radius--large: .375rem !default;
$t-Border__radius--small: .25rem !default;

$cb-Button__fontWeight: $t-Typography__fontWeight--bold !default;

$t-Link__color: $t-Color__primary !default;
$t-Link__color--hover: darken($t-Link__color, 15%) !default;
$t-Link__decoration: none !default;
$t-Link__decoration--hover: none !default;

$t-Spacing__base: 1rem !default;
$t-Spacing__gridGutter: 30px !default;

$t-Table__thead__backgroundColor: $t-Color__primary__gray--light !default;
$t-Table__borderColor: darken($t-Table__thead__backgroundColor, 10%) !default;
$t-Table__stripedColumn__background: url(/images/pattern-tbl-6x6.png) !default;
