
.c-Ribbon {
  position: fixed;
  width: 110px;
  height: 28px;
  background: $t-Color__primary;
  top: 11px;
  left: -31px;
  text-align: center;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: $t-Typography__fontWeight--bold;
  color: white;
  line-height: 28px;
  -ms-transform:rotate(-45deg);
  -webkit-transform:rotate(-45deg);
  transform:rotate(-45deg);
  // higher than navbar and header bar
  // lower than modal backdrop
  z-index: 1075;
}
