$c-Count__fontSize: 1.25rem !default;
$c-Count__capitalization: $b-Typography__capitalization--none !default;
$c-Count__number__color: $t-Color__secondary !default;
$c-Count__number__fontSize: 1.875rem !default;
$c-Count__number__fontWeight: $t-Typography__fontWeight--bold !default;
$c-Count__number__color--accent: $t-Color__accent !default;

//TODO: port other counts to this

.c-Count {
  display: inline-block;
  vertical-align: middle;
  text-transform: $c-Count__capitalization;
  font-size: ($c-Count__fontSize + 1rem) / 2;

  @include media-breakpoint-up(sm) {
    font-size: $c-Count__fontSize;
  }
}

.c-Count__number {
  display: inline-block;
  margin-right: $t-Spacing__base * .25;
  font-weight: $c-Count__number__fontWeight;
  font-size: ($c-Count__number__fontSize + 1rem) / 2;

  @include media-breakpoint-up(sm) {
    font-size: $c-Count__number__fontSize;
  }

  color: $c-Count__number__color;

  .c-Count--accent & {
    color: $c-Count__number__color--accent;
  }

  .c-Count--neutral & {
    color: $b-Typography__textColor;
  }
}


